import React, { useEffect } from "react";
import packageJson from "../package.json"; // Import package.json to access the version

const CacheHandler = () => {
  const clearCookies = () => {
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
    console.log("Cookies cleared.");
  };

  const clearServiceWorkerCaches = async () => {
    if ("caches" in window) {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        await caches.delete(cacheName);
        console.log(`Cache ${cacheName} cleared.`);
      }
    }
  };

  useEffect(() => {
    const clearAllCaches = async () => {
      console.log("Starting cache clearing process...");
      clearCookies();
      await clearServiceWorkerCaches();
      console.log("Cache clearing process completed. Reloading page...");
      localStorage.setItem("cacheCleared", packageJson.version); // Save the current version
      window.location.reload(true); // Forces a full page reload to fetch fresh assets
    };

    const savedVersion = localStorage.getItem("cacheCleared");
    const currentVersion = packageJson.version;

    if (savedVersion !== currentVersion) {
      clearAllCaches();
    } else {
      console.log("No cache clearing needed. Versions match.");
    }
  }, []); // Empty dependency array ensures it runs only once on mount

  return null; // No UI needed for this component
};

export default CacheHandler;
